<template>
  <div>
    <div class="mt-2" style="background-color:white;padding:10px" v-if="plotDetails">
      
      <plot-detail :plotDetails="plotDetails"></plot-detail>
      <div class="col-md-12 mt-2">
        <div class="row mb-2">
          <div class="col-md-6">
            <h5>Plots Assets</h5>
          </div>
          <div class="col-md-12 ">
              <table class="table table-sm">
                <thead>
                  <tr>
                    <td>#</td>
                    <td>Asset Image</td>
                    <td>Asset Name</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(plotAsset,index) in plotAssets" :key="index">
                     <td>
                       {{ 1 +index }}
                     </td>
                     <td>
                       <img :src=" plotAsset._asset_id.image" alt="" class="img-fluid" width="50px"> 
                     </td>
                     <td>
                        {{ plotAsset._asset_id.name }}
                     </td>
                  </tr>
                </tbody>
              </table>
          </div>
        </div>
           
      </div>
    </div>
  </div>
</template>
<script>

import plotDetail from "@/components/plots/details";

export default {
  components: {
    plotDetail
  },
  data() {
    return {
      plotDetails: false,
      plotAssets: false
    };
  },
  mounted() {
    this.getPlotDetails();
    this.getPlotAssets();
  },
  methods: {
    getPlotDetails() {
      let plot_id       = this.$route.params.plot_id;
      window.axios.get("plot/"+plot_id)
      .then((resp) => {   
        
        this.plotDetails = resp.data.data;
      });
    },
     getPlotAssets() {
      let plot_id       = this.$route.params.plot_id;
      window.axios.get("/plot-assets/"+plot_id)
      .then((resp) => {   
        this.plotAssets = resp.data.data;
      });
    },
   
  },
};
</script>
