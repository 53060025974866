<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <h5>Plot Details</h5>
          <div>
            <table class="table table-sm">
              <tr>
                <th>Code</th>
                <td>{{ plotDetails.code }}</td>
              </tr>
              <tr>
                <th>Area (in acres)</th>
                <td>{{ plotDetails.area }}</td>
              </tr>

              <tr>
                <th>Boundaries</th>
                <td>
                  <span
                    v-for="(boundary, index) in plotDetails.boundaries"
                    :key="index"
                  >
                    ({{ boundary.latitude }}, {{ boundary.longitude }})
                  </span>
                </td>
              </tr>
            </table>
          </div>
        </div>
       
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["plotDetails"],
};
</script>